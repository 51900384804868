exports.components = {
  "component---src-sites-type-ak-light-promocja-w-sieci-templates-add-domain-tsx": () => import("./../../../src/sites/typeAkLight/promocja-w-sieci/templates/AddDomain.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-promocja-w-sieci-templates-add-domain-tsx" */),
  "component---src-sites-type-ak-light-promocja-w-sieci-templates-category-tsx": () => import("./../../../src/sites/typeAkLight/promocja-w-sieci/templates/Category.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-promocja-w-sieci-templates-category-tsx" */),
  "component---src-sites-type-ak-light-promocja-w-sieci-templates-contact-tsx": () => import("./../../../src/sites/typeAkLight/promocja-w-sieci/templates/Contact.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-promocja-w-sieci-templates-contact-tsx" */),
  "component---src-sites-type-ak-light-promocja-w-sieci-templates-home-tsx": () => import("./../../../src/sites/typeAkLight/promocja-w-sieci/templates/Home.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-promocja-w-sieci-templates-home-tsx" */),
  "component---src-sites-type-ak-light-promocja-w-sieci-templates-not-found-tsx": () => import("./../../../src/sites/typeAkLight/promocja-w-sieci/templates/NotFound.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-promocja-w-sieci-templates-not-found-tsx" */),
  "component---src-sites-type-ak-light-promocja-w-sieci-templates-search-tsx": () => import("./../../../src/sites/typeAkLight/promocja-w-sieci/templates/Search.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-promocja-w-sieci-templates-search-tsx" */),
  "component---src-sites-type-ak-light-promocja-w-sieci-templates-terms-tsx": () => import("./../../../src/sites/typeAkLight/promocja-w-sieci/templates/Terms.tsx" /* webpackChunkName: "component---src-sites-type-ak-light-promocja-w-sieci-templates-terms-tsx" */)
}

